import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import SEO from "../components/SEO";

const BarWrapper = styled.div`
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0.2rem;
  }
`;

const PlayerContent = styled.main`
  display: grid;
  grid-template-columns: 1fr 2fr;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const PlayerProfile = styled.div``;

const ProfileSection = styled.section`
  margin: 1rem 1rem 2rem;
  border: solid 1px #999999;
  border-radius: 0.5rem;
  color: white;
  h1 {
    padding: 1rem;
    background: #20232a;
    border-radius: 0.5rem 0.5rem 0 0;
    font-weight: 700;
    text-align: center;
  }

  @media (max-width: 768px) {
    width: 320px;
  }
`;

const ResultSection = styled.section`
  margin: 1rem 1rem 2rem;
  border: solid 1px #999999;
  border-radius: 0.5rem;
  color: white;
  h1 {
    padding: 1rem;
    background: #20232a;
    border-bottom: solid 1px #999999;
    border-radius: 0.5rem 0.5rem 0 0;
    font-weight: 700;
    text-align: center;
  }

  @media (max-width: 768px) {
    width: 320px;
  }
`;

const SNSList = styled.li`
  display: flex;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-top: solid 1px #999999;
`;

const PlayerStats = styled.div``;

const ResultList = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0.2rem;
  }
`;

const ResultItem = styled.div`
  span {
    display: block;
    padding: 0.2rem;
    color: #999999;
    text-align: center;
  }
`;

const Counter = styled.div`
  padding: 0.8rem 1rem 1rem;
  width: 6rem;
  height: 6rem;
  background: #20232a;
  border-radius: 50%;
  color: #f4bc46;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
    /* padding: 0.6rem 1rem 1rem 0.9rem; */
    padding: 0.5rem 0 0 0;
    width: 4rem;
    height: 4rem;
    background: #20232a;
    border-radius: 50%;
    color: #f4bc46;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }
`;

const Books = styled.div`
  border-top: solid 1px #999999;
`;

const SNS = styled.div`
  border-top: solid 1px #999999;
`;

const PlayerPage = ({ data }) => {
  const player = data.playersActiveJson;
  const result = data.allRecordJson.nodes[data.allRecordJson.nodes.length - 1];

  // グラフのデータセットを用意
  let a = [];
  let b = [];
  let c = [];

  data.allRecordJson.nodes.map((tmp) => {
    a.push(tmp.season);
    b.push(tmp.win_rate);
    c.push(tmp.win);
  });

  const testdata = {
    labels: a,
    datasets: [
      {
        type: "line",
        label: "勝率",
        borderColor: "#db6d28",
        backgroundColor: "#db6d28",
        data: b,
        yAxisID: "y-axis-1",
      },
      {
        type: "bar",
        label: "勝数",
        borderColor: "#58a6ff",
        backgroundColor: "#238636",
        data: c,
        yAxisID: "y-axis-2",
      },
    ],
  };

  // フォーマット

  return (
    <Layout title={player.profile.name_en}>
      <SEO
        pagetitle={player.profile.name_en}
        pagedesc={player.profile.name_jp}
      />
      <ContentWrapper>
        <PlayerContent>
          <PlayerProfile>
            {/* <ProfileSection>
              <h1>SNS</h1>
              <SNSList>
                <li>Twitter</li>
                <li>Youtube</li>
                <li>instagram</li>
              </SNSList>
            </ProfileSection> */}
            <ProfileSection>
              <h1>基本情報</h1>
              <ul>
                <ListItem>
                  <span>棋士番号</span>
                  <span>{player.no}</span>
                </ListItem>
                <ListItem>
                  <span>名前</span>
                  <span>{player.name}</span>
                </ListItem>
                <ListItem>
                  <span>生年月日</span>
                  <span>
                    {player.profile.birth_day
                      .replace("(", "（")
                      .replace(")", "）")}
                  </span>
                </ListItem>
                <ListItem>
                  <span>出身地</span>
                  <span>{player.profile.birth_place}</span>
                </ListItem>
                <ListItem>
                  <span>竜王戦</span>
                  <span>
                    {player.profile.ryuuou
                      .replace("(", "（")
                      .replace(")", "）")}
                  </span>
                </ListItem>
                <ListItem>
                  <span>順位戦</span>
                  <span>
                    {player.profile.junni.replace("(", "（").replace(")", "）")}
                  </span>
                </ListItem>
              </ul>
            </ProfileSection>
            <ProfileSection>
              <h1>タイトル棋戦</h1>
              <ul>
                {player.win_major_tournament.map((title) => (
                  <ListItem>
                    <span>{title.name}</span>
                    <span>
                      {title.tournament.substring(
                        0,
                        title.tournament.indexOf("期") + 1
                      )}
                    </span>
                  </ListItem>
                ))}
              </ul>
            </ProfileSection>
            <ProfileSection>
              <h1>一般公式棋戦</h1>
              <ul>
                {player.win_minor_tournament.map((title) => (
                  <ListItem>
                    <span>{title.name}</span>
                    <span>
                      {title.tournament.substring(
                        0,
                        title.tournament.indexOf("回") + 1
                      )}
                    </span>
                  </ListItem>
                ))}
              </ul>
            </ProfileSection>
            <ProfileSection>
              <h1>昇段履歴</h1>
              <ul>
                {player.rank_up_history.map((history) => (
                  <ListItem>
                    <span>{history.name}</span>
                    <span>{history.rank_up}</span>
                  </ListItem>
                ))}
              </ul>
            </ProfileSection>
          </PlayerProfile>
          <PlayerStats>
            <ResultSection>
              <h1>今年度の成績</h1>
              <ResultList>
                <ResultItem>
                  <Counter>{result.match}</Counter>
                  <span>Match</span>
                </ResultItem>
                <ResultItem>
                  <Counter>{result.win}</Counter>
                  <span>Won</span>
                </ResultItem>
                <ResultItem>
                  <Counter>{result.lose}</Counter>
                  <span>Lost</span>
                </ResultItem>
                <ResultItem>
                  <Counter>{Math.round(result.win_rate * 100)}</Counter>
                  <span>Win rate %</span>
                </ResultItem>
              </ResultList>
            </ResultSection>
            <ResultSection>
              <h1>勝率と勝数の推移</h1>
              <BarWrapper>
                <Bar data={testdata} />
              </BarWrapper>
            </ResultSection>
          </PlayerStats>
        </PlayerContent>
        {/* <Books>アマゾンから取得した本を並べる</Books>
        <SNS>Twitterやyoutube、instagramから取得して直近の投稿を並べるよー</SNS> */}
      </ContentWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($no: String!, $name: String!) {
    playersActiveJson(no: { eq: $no }) {
      lady
      name
      no
      profile {
        birth_day
        birth_place
        junni
        master
        name_en
        name_jp
        no
        ryuuou
      }
      recent_match {
        date
        result
        opponent
        tournament
      }
      type
      win_major_tournament {
        name
        tournament
      }
      win_minor_tournament {
        name
        tournament
      }
      rank_up_history {
        name
        rank_up
      }
    }
    allRecordJson(filter: { name: { eq: $name } }, sort: { fields: season }) {
      nodes {
        lose
        match
        name
        season
        win
        win_rate
      }
    }
  }
`;

export default PlayerPage;
